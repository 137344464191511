import logo from "./logo.svg";
import "./App.css";
import copy from "copy-to-clipboard";

function App() {
  const handleClick = () => {
    alert("23234km");
    copy("dskfjsdlfkjsdlfkdsjfkk");
  };

  return (
    <div className="App">
      <header className="App-header">
        <button onClick={handleClick}>Copy to Clipboard</button>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
